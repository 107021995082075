import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="px-4 py-8 mx-auto max-w-7xl">
      <h1 className="mb-10 text-4xl font-bold text-center">
        Privacy Policy of bammbuu
      </h1>
      <p className="mb-2">
        This Application collects some Personal Data from its Users.
      </p>

      <div className="mb-2 space-y-2">
        <p>
          This document contains a section dedicated to Users in the United
          States and their privacy rights.
        </p>
        <p>
          This document contains a section dedicated to Users in Switzerland and
          their privacy rights.
        </p>
        <p>
          This document contains a section dedicated to Brazilian Users and
          their privacy rights.
        </p>
      </div>

      <p className="mb-8">
        This document can be printed for reference by using the print command in
        the settings of any browser.
      </p>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">Policy summary</h2>
        <h3 className="mb-3 text-xl font-medium">
          Personal Data processed for the following purposes and using the
          following services:
        </h3>

        <div className="space-y-6">
          <div>
            <h4 className="mb-2 text-lg font-medium">
              Access to third-party accounts
            </h4>
            <p className="font-medium">Facebook account access</p>
            <p>Permissions: device information; Email; Trackers; Usage Data</p>
          </div>

          <div>
            <h4 className="mb-2 text-lg font-medium">
              Hosting and backend infrastructure
            </h4>
            <p className="font-medium">Firebase Cloud Functions</p>
            <p>
              Personal Data: Usage Data; various types of Data as specified in
              the privacy policy of the service
            </p>
          </div>

          <div>
            <h4 className="mb-2 text-lg font-medium">
              Registration and authentication
            </h4>
            <p className="font-medium">Firebase Authentication</p>
            <p>Personal Data: email address; password; social media accounts</p>

            <p className="mt-2 font-medium">Google OAuth</p>
            <p>
              Personal Data: Trackers; Usage Data; various types of Data as
              specified in the privacy policy of the service
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Information on opting out of interest-based advertising
        </h2>
        <p>
          In addition to any opt-out feature provided by any of the services
          listed in this document, Users may learn more on how to generally opt
          out of interest-based advertising within the dedicated section of the
          Cookie Policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Further information about the processing of Personal Data
        </h2>
        <h3 className="mb-3 text-xl font-medium">Push notifications</h3>
        <p className="mb-4">
          This Application may send push notifications to the User to achieve
          the purposes outlined in this privacy policy.
        </p>
        <p>
          Users may in most cases opt-out of receiving push notifications by
          visiting their device settings, such as the notification settings for
          mobile phones, and then change those settings for this Application,
          some or all of the apps on the particular device. Users must be aware
          that disabling push notifications may negatively affect the utility of
          this Application.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">Contact information</h2>
        <div className="space-y-2">
          <h3 className="text-xl font-medium">Owner and Data Controller</h3>
          <p>
            Capital H Ventures LLC - 17350 State Hwy 249, Ste 220 #22916,
            Houston, Texas 77064 US
          </p>
          <p>Owner contact email: admin@bammbuu.co</p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">Full policy</h2>
        <div className="space-y-6">
          <div>
            <h3 className="mb-2 text-xl font-medium">
              Owner and Data Controller
            </h3>
            <p>
              Capital H Ventures LLC - 17350 State Hwy 249, Ste 220 #22916,
              Houston, Texas 77064 US
            </p>
            <p>Owner contact email: admin@bammbuu.co</p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">
              Types of Data collected
            </h3>
            <p className="mb-4">
              Among the types of Personal Data that this Application collects,
              by itself or through third parties, there are: email address;
              password; social media accounts; Usage Data; Trackers.
            </p>
            <div className="space-y-4">
              <p>
                Complete details on each type of Personal Data collected are
                provided in the dedicated sections of this privacy policy or by
                specific explanation texts displayed prior to the Data
                collection.
              </p>
              <p>
                Personal Data may be freely provided by the User, or, in case of
                Usage Data, collected automatically when using this Application.
              </p>
              <p>
                Unless specified otherwise, all Data requested by this
                Application is mandatory and failure to provide this Data may
                make it impossible for this Application to provide its services.
                In cases where this Application specifically states that some
                Data is not mandatory, Users are free not to communicate this
                Data without consequences to the availability or the functioning
                of the Service.
              </p>
              <p>
                Users who are uncertain about which Personal Data is mandatory
                are welcome to contact the Owner.
              </p>
              <p>
                Any use of Cookies – or of other tracking tools — by this
                Application or by the owners of third-party services used by
                this Application serves the purpose of providing the Service
                required by the User, in addition to any other purposes
                described in the present document and in the Cookie Policy.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Mode and place of processing the Data
        </h2>
        <div className="space-y-6">
          <div>
            <h3 className="mb-2 text-xl font-medium">Methods of processing</h3>
            <p className="mb-4">
              The Owner takes appropriate security measures to prevent
              unauthorized access, disclosure, modification, or unauthorized
              destruction of the Data.
            </p>
            <p>
              The Data processing is carried out using computers and/or IT
              enabled tools, following organizational procedures and modes
              strictly related to the purposes indicated. In addition to the
              Owner, in some cases, the Data may be accessible to certain types
              of persons in charge, involved with the operation of this
              Application (administration, sales, marketing, legal, system
              administration) or external parties (such as third-party technical
              service providers, mail carriers, hosting providers, IT companies,
              communications agencies) appointed, if necessary, as Data
              Processors by the Owner. The updated list of these parties may be
              requested from the Owner at any time.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Place</h3>
            <p className="mb-4">
              The Data is processed at the Owner's operating offices and in any
              other places where the parties involved in the processing are
              located.
            </p>
            <p>
              Depending on the User's location, data transfers may involve
              transferring the User's Data to a country other than their own. To
              find out more about the place of processing of such transferred
              Data, Users can check the section containing details about the
              processing of Personal Data.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Retention time</h3>
            <p>
              Unless specified otherwise in this document, Personal Data shall
              be processed and stored for as long as required by the purpose
              they have been collected for and may be retained for longer due to
              applicable legal obligation or based on the Users' consent.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          The purposes of processing
        </h2>
        <p className="mb-4">
          The Data concerning the User is collected to allow the Owner to
          provide its Service, comply with its legal obligations, respond to
          enforcement requests, protect its rights and interests (or those of
          its Users or third parties), detect any malicious or fraudulent
          activity, as well as the following: Access to third-party accounts,
          Registration and authentication and Hosting and backend
          infrastructure.
        </p>
        <p>
          For specific information about the Personal Data used for each
          purpose, the User may refer to the section "Detailed information on
          the processing of Personal Data".
        </p>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Facebook permissions asked by this Application
        </h2>
        <p className="mb-4">
          This Application may ask for some Facebook permissions allowing it to
          perform actions with the User's Facebook account and to retrieve
          information, including Personal Data, from it. This service allows
          this Application to connect with the User's account on the Facebook
          social network, provided by Facebook Inc.
        </p>
        <p className="mb-4">
          For more information about the following permissions, refer to the
          Facebook permissions documentation and to the Facebook privacy policy.
        </p>
        <p className="mb-4">The permissions asked are the following:</p>

        <div className="ml-4 space-y-4">
          <div>
            <h3 className="mb-2 text-lg font-medium">Basic information</h3>
            <p>
              By default, this includes certain User's Data such as id, name,
              picture, gender, and their locale. Certain connections of the
              User, such as the Friends, are also available. If the User has
              made more of their Data public, more information will be
              available.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">Device information</h3>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">Email</h3>
            <p>Provides access to the User's primary email address.</p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Detailed information on the processing of Personal Data
        </h2>
        <p className="mb-4">
          Personal Data is collected for the following purposes and using the
          following services:
        </p>

        <div className="ml-4 space-y-4">
          <div>
            <h3 className="mb-2 text-lg font-medium">
              Access to third-party accounts
            </h3>
            <p>
              This type of service allows this Application to access Data from
              your account on a third-party service and perform actions with it.
              These services are not activated automatically, but require
              explicit authorization by the User. Facebook account access (Meta
              Platforms, Inc.) This service allows this Application to connect
              with the User's account on the Facebook social network, provided
              by Meta Platforms, Inc. Permissions asked: device information;
              Email; Trackers; Usage Data. Place of processing: United States –
              Privacy Policy – Opt out. Category of Personal Information
              collected according to the CCPA: identifiers; internet or other
              electronic network activity information.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">
              Hosting and backend infrastructure
            </h3>
            <p>
              This type of service has the purpose of hosting Data and files
              that enable this Application to run and be distributed as well as
              to provide a ready-made infrastructure to run specific features or
              parts of this Application. Some services among those listed below,
              if any, may work through geographically distributed servers,
              making it difficult to determine the actual location where the
              Personal Data are stored. Firebase Cloud Functions (Google LLC)
              Firebase Cloud Functions is a hosting and backend service provided
              by Google LLC. Personal Data processed: Usage Data; various types
              of Data as specified in the privacy policy of the service. Place
              of processing: United States – Privacy Policy. Category of
              Personal Information collected according to the CCPA: identifiers;
              internet or other electronic network activity information.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">
              Registration and authentication
            </h3>
            <p>
              By registering or authenticating, Users allow this Application to
              identify them and give them access to dedicated services.
              Depending on what is described below, third parties may provide
              registration and authentication services. In this case, this
              Application will be able to access some Data, stored by these
              third-party services, for registration or identification purposes.
              Some of the services listed below may also collect Personal Data
              for targeting and profiling purposes; to find out more, please
              refer to the description of each service. Firebase Authentication
              (Google LLC) Firebase Authentication is a registration and
              authentication service provided by Google LLC. To simplify the
              registration and authentication process, Firebase Authentication
              can make use of third-party identity providers and save the
              information on its platform. Personal Data processed: email
              address; password; social media accounts. Place of processing:
              United States – Privacy Policy. Category of Personal Information
              collected according to the CCPA: identifiers. Google OAuth (Google
              LLC) Google OAuth is a registration and authentication service
              provided by Google LLC and is connected to the Google network.
              Personal Data processed: Trackers; Usage Data; various types of
              Data as specified in the privacy policy of the service. Place of
              processing: United States – Privacy Policy. Category of Personal
              Information collected according to the CCPA: identifiers; internet
              or other electronic network activity information.
            </p>
          </div>
        </div>
      </section>
      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Information on opting out of interest-based advertising
        </h2>
        <p className="mb-4">
          In addition to any opt-out feature provided by any of the services
          listed in this document, Users may learn more on how to generally opt
          out of interest-based advertising within the dedicated section of the
          Cookie Policy.
        </p>

        <div className="ml-4 space-y-4">
          <div>
            <h3 className="mb-2 text-lg font-medium">Push notifications </h3>
            <p>
              This Application may send push notifications to the User to
              achieve the purposes outlined in this privacy policy. Users may in
              most cases opt-out of receiving push notifications by visiting
              their device settings, such as the notification settings for
              mobile phones, and then change those settings for this
              Application, some or all of the apps on the particular device.
              Users must be aware that disabling push notifications may
              negatively affect the utility of this Application.
            </p>
          </div>
        </div>
      </section>
      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Further Information for Users
        </h2>
        <div className="space-y-6">
          <div>
            <h3 className="mb-2 text-xl font-medium">
              Legal basis of processing
            </h3>
            <p className="mb-4">
              The Owner may process Personal Data relating to Users if one of
              the following applies:
            </p>
            <ul className="ml-6 space-y-2 list-disc">
              <li>
                Users have given their consent for one or more specific
                purposes.
              </li>
              <li>
                provision of Data is necessary for the performance of an
                agreement with the User and/or for any pre-contractual
                obligations thereof;
              </li>
              <li>
                processing is necessary for compliance with a legal obligation
                to which the Owner is subject;
              </li>
              <li>
                processing is related to a task that is carried out in the
                public interest or in the exercise of official authority vested
                in the Owner;
              </li>
              <li>
                processing is necessary for the purposes of the legitimate
                interests pursued by the Owner or by a third party.
              </li>
            </ul>
            <p className="mt-4">
              In any case, the Owner will gladly help to clarify the specific
              legal basis that applies to the processing, and in particular
              whether the provision of Personal Data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-xl font-medium">
              Further information about retention time
            </h3>

            <p className="mt-4">
              Unless specified otherwise in this document, Personal Data shall
              be processed and stored for as long as required by the purpose
              they have been collected for and may be retained for longer due to
              applicable legal obligation or based on the Users’ consent.
              Therefore: Personal Data collected for purposes related to the
              performance of a contract between the Owner and the User shall be
              retained until such contract has been fully performed. Personal
              Data collected for the purposes of the Owner’s legitimate
              interests shall be retained as long as needed to fulfill such
              purposes. Users may find specific information regarding the
              legitimate interests pursued by the Owner within the relevant
              sections of this document or by contacting the Owner. The Owner
              may be allowed to retain Personal Data for a longer period
              whenever the User has given consent to such processing, as long as
              such consent is not withdrawn. Furthermore, the Owner may be
              obliged to retain Personal Data for a longer period whenever
              required to fulfil a legal obligation or upon order of an
              authority. Once the retention period expires, Personal Data shall
              be deleted. Therefore, the right of access, the right to erasure,
              the right to rectification and the right to data portability
              cannot be enforced after expiration of the retention period.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-xl font-medium">
              The rights of Users based on the General Data Protection
              Regulation (GDPR)
            </h3>

            <p className="mt-4">
              Users may exercise certain rights regarding their Data processed
              by the Owner. In particular, Users have the right to do the
              following, to the extent permitted by law: Withdraw their consent
              at any time. Users have the right to withdraw consent where they
              have previously given their consent to the processing of their
              Personal Data. Object to processing of their Data. Users have the
              right to object to the processing of their Data if the processing
              is carried out on a legal basis other than consent. Access their
              Data. Users have the right to learn if Data is being processed by
              the Owner, obtain disclosure regarding certain aspects of the
              processing and obtain a copy of the Data undergoing processing.
              Verify and seek rectification. Users have the right to verify the
              accuracy of their Data and ask for it to be updated or corrected.
              Restrict the processing of their Data. Users have the right to
              restrict the processing of their Data. In this case, the Owner
              will not process their Data for any purpose other than storing it.
              Have their Personal Data deleted or otherwise removed. Users have
              the right to obtain the erasure of their Data from the Owner.
              Receive their Data and have it transferred to another controller.
              Users have the right to receive their Data in a structured,
              commonly used and machine readable format and, if technically
              feasible, to have it transmitted to another controller without any
              hindrance. Lodge a complaint. Users have the right to bring a
              claim before their competent data protection authority. Users are
              also entitled to learn about the legal basis for Data transfers
              abroad including to any international organization governed by
              public international law or set up by two or more countries, such
              as the UN, and about the security measures taken by the Owner to
              safeguard their Data.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-xl font-medium">
              Details about the right to object to processing
            </h3>

            <p className="mt-4">
              Where Personal Data is processed for a public interest, in the
              exercise of an official authority vested in the Owner or for the
              purposes of the legitimate interests pursued by the Owner, Users
              may object to such processing by providing a ground related to
              their particular situation to justify the objection. Users must
              know that, however, should their Personal Data be processed for
              direct marketing purposes, they can object to that processing at
              any time, free of charge and without providing any justification.
              Where the User objects to processing for direct marketing
              purposes, the Personal Data will no longer be processed for such
              purposes. To learn whether the Owner is processing Personal Data
              for direct marketing purposes, Users may refer to the relevant
              sections of this document.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-xl font-medium">
              How to exercise these rights
            </h3>

            <p className="mt-4">
              Any requests to exercise User rights can be directed to the Owner
              through the contact details provided in this document. Such
              requests are free of charge and will be answered by the Owner as
              early as possible and always within one month, providing Users
              with the information required by law. Any rectification or erasure
              of Personal Data or restriction of processing will be communicated
              by the Owner to each recipient, if any, to whom the Personal Data
              has been disclosed unless this proves impossible or involves
              disproportionate effort. At the Users’ request, the Owner will
              inform them about those recipients.
            </p>
          </div>
        </div>
      </section>

      {/* Additional sections for US, Brazil, and Switzerland specific information */}
      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Further information for Users in Switzerland
        </h2>
        <p className="mb-4">
          This section applies to Users in Switzerland, and, for such Users,
          supersedes any other possibly divergent or conflicting information
          contained in the privacy policy. Further details regarding the
          categories of Data processed, the purposes of processing, the
          categories of recipients of the personal data, if any, the retention
          period and further information about Personal Data can be found in the
          section titled “Detailed information on the processing of Personal
          Data” within this document
        </p>
        <div>
          <h3 className="mb-2 text-xl font-medium">
            The rights of Users according to the Swiss Federal Act on Data
            Protection
          </h3>

          <p className="mt-4">
            Users may exercise certain rights regarding their Data within the
            limits of law, including the following: right of access to Personal
            Data; right to object to the processing of their Personal Data
            (which also allows Users to demand that processing of Personal Data
            be restricted, Personal Data be deleted or destroyed, specific
            disclosures of Personal Data to third parties be prohibited); right
            to receive their Personal Data and have it transferred to another
            controller (data portability); right to ask for incorrect Personal
            Data to be corrected.
          </p>
        </div>
        <div>
          <h3 className="mb-2 text-xl font-medium">
            How to exercise these rights
          </h3>

          <p className="mt-4">
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. Such requests
            are free of charge and will be answered by the Owner as early as
            possible, providing Users with the information required by law.
          </p>
        </div>
      </section>
      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Further information for Users in Brazil
        </h2>
        <p className="mb-4">
          This section of the document integrates with and supplements the
          information contained in the rest of the privacy policy and is
          provided by the entity running this Application and, if the case may
          be, its parent, subsidiaries and affiliates (for the purposes of this
          section referred to collectively as “we”, “us”, “our”). This section
          applies to all Users in Brazil (Users are referred to below, simply as
          “you”, “your”, “yours”), according to the "Lei Geral de Proteção de
          Dados" (the "LGPD"), and for such Users, it supersedes any other
          possibly divergent or conflicting information contained in the privacy
          policy. This part of the document uses the term “personal information“
          as it is defined in the LGPD.
        </p>
        <div>
          <h3 className="mb-2 text-xl font-medium">
            The grounds on which we process your personal information
          </h3>

          <p className="mt-4">
            We can process your personal information solely if we have a legal
            basis for such processing. Legal bases are as follows: your consent
            to the relevant processing activities; compliance with a legal or
            regulatory obligation that lies with us; the carrying out of public
            policies provided in laws or regulations or based on contracts,
            agreements and similar legal instruments; studies conducted by
            research entities, preferably carried out on anonymized personal
            information; the carrying out of a contract and its preliminary
            procedures, in cases where you are a party to said contract; the
            exercising of our rights in judicial, administrative or arbitration
            procedures; protection or physical safety of yourself or a third
            party; the protection of health – in procedures carried out by
            health entities or professionals; our legitimate interests, provided
            that your fundamental rights and liberties do not prevail over such
            interests; and credit protection.
          </p>
        </div>
        <div>
          <h3 className="my-2 text-xl font-medium">
            Categories of personal information processed
          </h3>

          <p className="my-2">
            To find out what categories of your personal information are
            processed, you can read the section titled “Detailed information on
            the processing of Personal Data” within this document.
          </p>
        </div>
        <div>
          <h3 className="my-2 text-xl font-medium">
            Why we process your personal information
          </h3>

          <p className="my-2">
            To find out why we process your personal information, you can read
            the sections titled “Detailed information on the processing of
            Personal Data” and “The purposes of processing” within this
            document.
          </p>
        </div>
        <div>
          <h3 className="my-2 text-xl font-medium">
            Your Brazilian privacy rights
          </h3>

          <p className="my-2">
            You have the right to: obtain confirmation of the existence of
            processing activities on your personal information; access to your
            personal information; have incomplete, inaccurate or outdated
            personal information rectified; obtain the anonymization, blocking
            or elimination of your unnecessary or excessive personal
            information, or of information that is not being processed in
            compliance with the LGPD; obtain information on the possibility to
            provide or deny your consent and the consequences thereof; obtain
            information about the third parties with whom we share your personal
            information; obtain, upon your express request, the portability of
            your personal information (except for anonymized information) to
            another service or product provider, provided that our commercial
            and industrial secrets are safeguarded; obtain the deletion of your
            personal information being processed if the processing was based
            upon your consent, unless one or more exceptions provided for in
            art. 16 of the LGPD apply; revoke your consent at any time; lodge a
            complaint related to your personal information with the ANPD (the
            National Data Protection Authority) or with consumer protection
            bodies; oppose a processing activity in cases where the processing
            is not carried out in compliance with the provisions of the law;
            request clear and adequate information regarding the criteria and
            procedures used for an automated decision; and request the review of
            decisions made solely on the basis of the automated processing of
            your personal information, which affect your interests. These
            include decisions to define your personal, professional, consumer
            and credit profile, or aspects of your personality.
          </p>
        </div>
        <div>
          <h3 className="my-2 text-xl font-medium">How to file your request</h3>

          <p className="my-2">
            You can file your express request to exercise your rights free from
            any charge, at any time, by using the contact details provided in
            this document, or via your legal representative.
          </p>
        </div>
        <div>
          <h3 className="my-2 text-xl font-medium">
            How and when we will respond to your request
          </h3>

          <p className="my-2">
            We will strive to promptly respond to your requests. In any case,
            should it be impossible for us to do so, we’ll make sure to
            communicate to you the factual or legal reasons that prevent us from
            immediately, or otherwise ever, complying with your requests. In
            cases where we are not processing your personal information, we will
            indicate to you the physical or legal person to whom you should
            address your requests, if we are in the position to do so. In the
            event that you file an access or personal information processing
            confirmation request, please make sure that you specify whether
            you’d like your personal information to be delivered in electronic
            or printed form. You will also need to let us know whether you want
            us to answer your request immediately, in which case we will answer
            in a simplified fashion, or if you need a complete disclosure
            instead. In the latter case, we’ll respond within 15 days from the
            time of your request, providing you with all the information on the
            origin of your personal information, confirmation on whether or not
            records exist, any criteria used for the processing and the purposes
            of the processing, while safeguarding our commercial and industrial
            secrets. In the event that you file a rectification, deletion,
            anonymization or personal information blocking request, we will make
            sure to immediately communicate your request to other parties with
            whom we have shared your personal information in order to enable
            such third parties to also comply with your request — except in
            cases where such communication is proven impossible or involves
            disproportionate effort on our side.
          </p>
        </div>
        <div>
          <h3 className="my-2 text-xl font-medium">
            Transfer of personal information outside of Brazil permitted by the
            law
          </h3>

          <p className="my-2">
            We are allowed to transfer your personal information outside of the
            Brazilian territory in the following cases: when the transfer is
            necessary for international legal cooperation between public
            intelligence, investigation and prosecution bodies, according to the
            legal means provided by the international law; when the transfer is
            necessary to protect your life or physical security or those of a
            third party; when the transfer is authorized by the ANPD; when the
            transfer results from a commitment undertaken in an international
            cooperation agreement; when the transfer is necessary for the
            execution of a public policy or legal attribution of public service;
            when the transfer is necessary for compliance with a legal or
            regulatory obligation, the carrying out of a contract or preliminary
            procedures related to a contract, or the regular exercise of rights
            in judicial, administrative or arbitration procedures.
          </p>
        </div>
      </section>
      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Further information for Users in the United States
        </h2>
        <p className="mb-4">
          This part of the document integrates with and supplements the
          information contained in the rest of the privacy policy and is
          provided by the business running this Application and, if the case may
          be, its parent, subsidiaries and affiliates (for the purposes of this
          section referred to collectively as "we", "us", "our").
        </p>
        <div className="space-y-4">
          <p>
            The information contained in this section applies to all Users who
            are residents in the following states: California, Virginia,
            Colorado, Connecticut, Utah, Texas, Oregon, Nevada, Delaware, Iowa,
            New Hampshire, New Jersey, Nebraska and Montana.
          </p>
          <p>
            For such Users, this information supersedes any other possibly
            divergent or conflicting provisions contained in the privacy policy.
          </p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Additional information about Data collection and processing
        </h2>

        <div className="ml-4 space-y-4">
          <div>
            <h3 className="mb-2 text-lg font-medium">Legal action</h3>
            <p>
              The User's Personal Data may be used for legal purposes by the
              Owner in Court or in the stages leading to possible legal action
              arising from improper use of this Application or the related
              Services. The User declares to be aware that the Owner may be
              required to reveal personal data upon request of public
              authorities.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">
              Additional information about User's Personal Data
            </h3>
            <p>
              In addition to the information contained in this privacy policy,
              this Application may provide the User with additional and
              contextual information concerning particular Services or the
              collection and processing of Personal Data upon request
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">
              System logs and maintenance
            </h3>
            <p>
              For operation and maintenance purposes, this Application and any
              third-party services may collect files that record interaction
              with this Application (System logs) or use other Personal Data
              (such as the IP Address) for this purpose.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-lg font-medium">
              Information not contained in this policy
            </h3>
            <p>
              More details concerning the collection or processing of Personal
              Data may be requested from the Owner at any time. Please see the
              contact information at the beginning of this document.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-lg font-medium">
              Changes to this privacy policy
            </h3>
            <p>
              The Owner reserves the right to make changes to this privacy
              policy at any time by notifying its Users on this page and
              possibly within this Application and/or - as far as technically
              and legally feasible - sending a notice to Users via any contact
              information available to the Owner. It is strongly recommended to
              check this page often, referring to the date of the last
              modification listed at the bottom.
            </p>
          </div>
        </div>
      </section>
      <section className="mb-8">
        <div className="ml-4 space-y-4">
          <div>
            <h3 className="mb-2 text-lg font-medium">Notice at collection</h3>
            <p>
              Personal Information collected or disclosed: Email, Trackers,
              Usage Data, device information, email address, social media
              accounts, various types of Data as specified in the privacy policy
              of the service Sensitive Personal Information collected or
              disclosed. Password Purposes, Access to third-party accounts
              Registration and authentication Hosting and backend infrastructure
              Further information about Personal Data Registration and
              authentication Retention period: for the time necessary to fulfill
              the purpose Sold or Shared. No Targeted Advertising, No
              Third-parties: Meta Platforms, Inc., Google LLC
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">
              Internet or other electronic network activity information
            </h3>
            <p>
              Personal Information collected or disclosed: Email, Trackers,
              Usage Data, device information, various types of Data as specified
              in the privacy policy of the service Purposes: Access to
              third-party accounts Hosting and backend infrastructure
              Registration and authentication Retention period: for the time
              necessary to fulfill the purpose Sold or Shared. No Targeted
              Advertising, No Third-parties: Meta Platforms, Inc., Google LLC
            </p>
            <h3 className="my-2 text-lg font-medium">
              What are the sources of the Personal Information we collect?
            </h3>
            <p>
              We collect the above-mentioned categories of Personal Information,
              either directly or indirectly, from you when you use this
              Application. For example, you directly provide your Personal
              Information when you submit requests via any forms on this
              Application. You also provide Personal Information indirectly when
              you navigate this Application, as Personal Information about you
              is automatically observed and collected. Finally, we may collect
              your Personal Information from third parties that work with us in
              connection with the Service or with the functioning of this
              Application and features thereof.
            </p>
            <h3 className="my-2 text-lg font-medium">
              Your privacy rights under US state laws
            </h3>
            <p>
              You may exercise certain rights regarding your Personal
              Information. In particular, to the extent permitted by applicable
              law, you have: the right to access Personal Information: the right
              to know. You have the right to request that we confirm whether or
              not we are processing your Personal Information. You also have the
              right to access such Personal Information; the right to correct
              inaccurate Personal Information. You have the right to request
              that we correct any inaccurate Personal Information we maintain
              about you; the right to request the deletion of your Personal
              Information. You have the right to request that we delete any of
              your Personal Information; the right to obtain a copy of your
              Personal Information. We will provide your Personal Information in
              a portable and usable format that allows you to transfer data
              easily to another entity – provided that this is technically
              feasible; the right to opt out from the Sale of your Personal
              Information; We will not discriminate against you for exercising
              your privacy rights. the right to non-discrimination.
            </p>
            <div>
              <h3 className="my-2 text-lg font-medium">
                Additional rights for Users residing in California
              </h3>
              <p>
                In addition to the rights listed above common to all Users in
                the United States, as a User residing in California, you have
                The right to opt out of the Sharing of your Personal Information
                for cross-context behavioral advertising; The right to request
                to limit our use or disclosure of your Sensitive Personal
                Information to only that which is necessary to perform the
                services or provide the goods, as is reasonably expected by an
                average consumer. Please note that certain exceptions outlined
                in the law may apply, such as, when the collection and
                processing of Sensitive Personal Information is necessary to
                verify or maintain the quality or safety of our service.
              </p>
            </div>
            <div>
              <h3 className="my-2 text-lg font-medium">
                Additional rights for Users residing in Virginia, Colorado,
                Connecticut, Texas, Oregon, Nevada, Delaware, Iowa, New
                Hampshire, New Jersey, Nebraska and Montana
              </h3>
              <p>
                In addition to the rights listed above common to all Users in
                the United States, as a User residing in Virginia, Colorado,
                Connecticut, Texas, Oregon, Nevada, Delaware, Iowa, New
                Hampshire, New Jersey, Nebraska and Montana you have The right
                to opt out of the processing of your personal information for
                Targeted Advertising or profiling in furtherance of decisions
                that produce legal or similarly significant effects concerning
                you; The right to freely give, deny or withdraw your consent for
                the processing of your Sensitive Personal Information. Please
                note that certain exceptions outlined in the law may apply, such
                as, but not limited to, when the collection and processing of
                Sensitive Personal Information is necessary for the provision of
                a product or service specifically requested by the consumer.
              </p>
            </div>
            <div>
              <h3 className="my-2 text-lg font-medium">
                Additional rights for users residing in Utah and Iowa
              </h3>
              <p>
                In addition to the rights listed above common to all Users in
                the United States, as a User residing in Utah and Iowa, you have
                The right to opt out of the processing of your Personal
                Information for Targeted Advertising; The right to opt out of
                the processing of your Sensitive Personal Information. Please
                note that certain exceptions outlined in the law may apply, such
                as, but not limited to, when the collection and processing of
                Sensitive Personal Information is necessary for the provision of
                a product or service specifically requested by the consumer.
              </p>
            </div>
            <div>
              <h3 className="my-2 text-lg font-medium">
                Additional rights for users residing in Utah and Iowa
              </h3>
              <p>
                In addition to the rights listed above common to all Users in
                the United States, as a User residing in Utah and Iowa, you have
                The right to opt out of the processing of your Personal
                Information for Targeted Advertising; The right to opt out of
                the processing of your Sensitive Personal Information. Please
                note that certain exceptions outlined in the law may apply, such
                as, but not limited to, when the collection and processing of
                Sensitive Personal Information is necessary for the provision of
                a product or service specifically requested by the consumer.
              </p>
            </div>
          </div>

          <div>
            <h3 className="mb-2 text-lg font-medium">
              How to exercise your privacy rights under US state laws
            </h3>
            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document. For us to respond to your request, we must know who
              you are. We will not respond to any request if we are unable to
              verify your identity and therefore confirm the Personal
              Information in our possession relates to you. You are not required
              to create an account with us to submit your request. We will use
              any Personal Information collected from you in connection with the
              verification of your request solely for verification and shall not
              further disclose the Personal Information, retain it longer than
              necessary for purposes of verification, or use it for unrelated
              purposes. If you are an adult, you can make a request on behalf of
              a child under your parental authority. How to exercise your rights
              to opt out In addition to what is stated above, to exercise your
              right to opt-out of Sale or Sharing and Targeted Advertising you
              can also use the privacy choices link provided on this
              Application. If you want to submit requests to opt out of Sale or
              Sharing and Targeted Advertising activities via a user-enabled
              global privacy control, such as for example the Global Privacy
              Control (“GPC”), you are free to do so and we will abide by such
              request in a frictionless manner. How and when we are expected to
              handle your request We will respond to your request without undue
              delay, but in all cases within the timeframe required by
              applicable law. Should we need more time, we will explain to you
              the reasons why, and how much more time we need. Should we deny
              your request, we will explain to you the reasons behind our denial
              (where envisaged by applicable law you may then contact the
              relevant authority to submit a complaint). We do not charge a fee
              to process or respond to your request unless such request is
              manifestly unfounded or excessive and in all other cases where it
              is permitted by the applicable law. In such cases, we may charge a
              reasonable fee or refuse to act on the request. In either case, we
              will communicate our choices and explain the reasons behind them.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-lg font-medium">
              How to exercise your privacy rights under US state laws
            </h3>
            <p>
              To exercise the rights described above, you need to submit your
              request to us by contacting us via the contact details provided in
              this document. For us to respond to your request, we must know who
              you are. We will not respond to any request if we are unable to
              verify your identity and therefore confirm the Personal
              Information in our possession relates to you. You are not required
              to create an account with us to submit your request. We will use
              any Personal Information collected from you in connection with the
              verification of your request solely for verification and shall not
              further disclose the Personal Information, retain it longer than
              necessary for purposes of verification, or use it for unrelated
              purposes. If you are an adult, you can make a request on behalf of
              a child under your parental authority. How to exercise your rights
              to opt out In addition to what is stated above, to exercise your
              right to opt-out of Sale or Sharing and Targeted Advertising you
              can also use the privacy choices link provided on this
              Application. If you want to submit requests to opt out of Sale or
              Sharing and Targeted Advertising activities via a user-enabled
              global privacy control, such as for example the Global Privacy
              Control (“GPC”), you are free to do so and we will abide by such
              request in a frictionless manner. How and when we are expected to
              handle your request We will respond to your request without undue
              delay, but in all cases within the timeframe required by
              applicable law. Should we need more time, we will explain to you
              the reasons why, and how much more time we need. Should we deny
              your request, we will explain to you the reasons behind our denial
              (where envisaged by applicable law you may then contact the
              relevant authority to submit a complaint). We do not charge a fee
              to process or respond to your request unless such request is
              manifestly unfounded or excessive and in all other cases where it
              is permitted by the applicable law. In such cases, we may charge a
              reasonable fee or refuse to act on the request. In either case, we
              will communicate our choices and explain the reasons behind them.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">
          Definitions and legal references
        </h2>
        <div className="space-y-6">
          <div>
            <h3 className="mb-2 text-xl font-medium">
              Personal Data (or Data) / Personal Information (or Information)
            </h3>
            <p>
              Any information that directly, indirectly, or in connection with
              other information — including a personal identification number —
              allows for the identification or identifiability of a natural
              person.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">
              Sensitive Personal Information
            </h3>
            <p>
              Sensitive Personal Information means any Personal Information that
              is not publicly available and reveals information considered
              sensitive according to the applicable privacy law.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Usage Data</h3>
            <p>
              Information collected automatically through this Application (or
              third-party services employed in this Application), which can
              include: the IP addresses or domain names of the computers
              utilized by the Users who use this Application, the URI addresses
              (Uniform Resource Identifier), the time of the request, the method
              utilized to submit the request to the server, the size of the file
              received in response, the numerical code indicating the status of
              the server's answer (successful outcome, error, etc.), the country
              of origin, the features of the browser and the operating system
              utilized by the User, the various time details per visit (e.g.,
              the time spent on each page within the Application) and the
              details about the path followed within the Application with
              special reference to the sequence of pages visited, and other
              parameters about the device operating system and/or the User's IT
              environment.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">User</h3>
            <p>
              The individual using this Application who, unless otherwise
              specified, coincides with the Data Subject.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Data Subject</h3>
            <p>The natural person to whom the Personal Data refers.</p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">
              Data Processor (or Processor)
            </h3>
            <p>
              The natural or legal person, public authority, agency or other
              body which processes Personal Data on behalf of the Controller, as
              described in this privacy policy.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">
              Data Controller (or Owner)
            </h3>
            <p>
              The natural or legal person, public authority, agency or other
              body which, alone or jointly with others, determines the purposes
              and means of the processing of Personal Data, including the
              security measures concerning the operation and use of this
              Application. The Data Controller, unless otherwise specified, is
              the Owner of this Application.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">This Application</h3>
            <p>
              The means by which the Personal Data of the User is collected and
              processed.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Service</h3>
            <p>
              The service provided by this Application as described in the
              relative terms (if available) and on this site/application.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Sale</h3>
            <p>
              Sale means any exchange of Personal Information by the Owner to a
              third party, for monetary or other valuable consideration, as
              defined by the applicable privacy US state law. Please note that
              the exchange of Personal Information with a service provider
              pursuant to a written contract that meets the requirements set by
              the applicable law, does not constitute a Sale of your Personal
              Information.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Sharing</h3>
            <p>
              Sharing means any sharing, renting, releasing, disclosing,
              disseminating, making available, transferring, or otherwise
              communicating orally, in writing, or by electronic or other means,
              a consumer's Personal Information by the business to a third party
              for cross-context behavioral advertising, whether for monetary or
              other valuable consideration, including transactions between a
              business and a third party for cross-context behavioral
              advertising for the benefit of a business in which no money is
              exchanged, as defined by the California privacy laws. Please note
              that the exchange of Personal Information with a service provider
              pursuant to a written contract that meets the requirements set by
              the California privacy laws, does not constitute sharing of your
              Personal Information.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Targeted advertising</h3>
            <p>
              Targeted advertising means displaying advertisements to a consumer
              where the advertisement is selected based on Personal Information
              obtained from that consumer's activities over time and across
              nonaffiliated websites or online applications to predict such
              consumer's preferences or interests, as defined by the applicable
              privacy US state law.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">European Union (or EU)</h3>
            <p>
              Unless otherwise specified, all references made within this
              document to the European Union include all current member states
              to the European Union and the European Economic Area.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Cookie</h3>
            <p>
              Cookies are Trackers consisting of small sets of data stored in
              the User's browser.
            </p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-medium">Tracker</h3>
            <p>
              Tracker indicates any technology - e.g Cookies, unique
              identifiers, web beacons, embedded scripts, e-tags and
              fingerprinting - that enables the tracking of Users, for example
              by accessing or storing information on the User's device.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="mb-4 text-2xl font-semibold">Legal information</h2>
        <p className="mb-4">
          This privacy statement has been prepared based on provisions of
          multiple legislations.
        </p>
        <p>
          This privacy policy relates solely to this Application, if not stated
          otherwise within this document.
        </p>
      </section>
      <section className="mt-8 text-sm text-gray-600">
        <p>
          Users are responsible for any third-party Personal Data obtained,
          published or shared through this Application.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
